@import "../style/globals";

.light-weight-header {
  font-size: 20px;
  font-weight: 300;
  span {
    padding-left: 10px;
  }
}
.table-responsive {
  &.cart {
    .table-header, .table-content-item{
      grid-template-areas: "id name quantity itemprice totalprice";
      grid-template-columns: repeat(5, 20%);
      & > div {
        word-break: break-word;
        padding: 18px;
        justify-content: center;
      }
    }
    .id {
      grid-area: id;
      word-break: break-all;
    }
    .name {
      grid-area: name;
      word-break: break-all;
    }
    .quantityWrapper {
      grid-area: quantity;
      flex-direction: column;
      .quantity{
        display: flex;
      }
      .quantityWarning{
        margin-top: 10px;
        color: $redNotification;
      }
    }
    .itemprice {
      grid-area: itemprice;
    }
    .totalprice  {
      grid-area: totalprice;
    }

    .table-footer {
      grid-template-columns: repeat(5, 20%);
      align-items: center;
      .button {
        border-radius: 8px;
      }

      .table_total_price {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid $offWhite;
        //border-radius: 8px;

        &.table_total_price_label {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          span {
            color: $darkBlue;
            font-size: 15px;
            font-weight: 500;
            padding: 17px;
          }
        }
        &.table_total_price_number {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-left: none;
          span {
            color: $darkBlue;
            font-size: 17px;
            font-weight: 800;
            padding: 16px;
          }

        }
      }
    }
  }

}


.terms {
  label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: $mobileMaxWidth){
      flex-wrap: wrap;
    }
    input {
      &[type="checkbox"] {
        width: auto;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }
    strong {
      color: $darkBlue;
    }
    a{
      color: $darkBlue;
    }
    span {
      color: $gray;
      font-size: 15px;
      font-weight: 500;
      min-width: 80px;


      &.checkbox {
        position: relative;
        top: 0;
        left: 0;
        height: 19px;
        width: 19px;
        background-color: $white;
        border: 1px solid $lightestBlue;
        min-width: auto;
        border-radius: 4px;

        &.checked {

          &:after{
            content: ' ';
            background-position: center center;
            background-repeat: no-repeat;
            // background-size: contain;
            display: block;
            height: 17px;
            width: 17px;
            background-image: url("/staticImages/icons/check-box.svg");
          }
        }
      }
      &.radio {
        position: relative;
        margin: 10px;
        height: 18px;
        width: 18px;
        background-color: $white;
        border: 1px solid $lightestBlue;
        border-radius: 50%;
        &.checked {
          background-color: $lightGray;
          &:after {
            content: ' ';
            display: block;
            height: 9px;
            width: 9px;
            background-color: $darkBlue;
            border-radius: 50%;
            top: 3.5px;
            left: 3.5px;
            position: relative;
          }
        }
      }
    }
  }
}
.submit {
  display: flex;
  justify-content: center;
  .button {
    text-transform: uppercase;
    padding: 15px 45px;
    font-size: 15px;
    font-weight: bold;
  }
}
.panel{
  label.grid-80.phoneNumber{
    grid-template-columns: 70px 115px auto;
    grid-column-gap: 10px;
    padding-right: 8%;
    select{
      width: 100%;
    }
    input{
      width: 100%;
    }
  }
  @media (max-width: $mobileMaxWidth) {
    label{
      &.grid-80.phoneNumber{
        grid-template-columns: 70px 60px auto;
        select{
          padding: 13px 0 12px 7px;
        }
      }
      select, input{
        padding: 13px 13px 12px 13px;
      }
    }
  }
}
@media (max-width: $mobileMaxWidth) {
  .mobileCartProduct{
    margin-bottom: 15px !important;
    padding-bottom: 5px;
    border-bottom: 1px solid $gray;
    &:last-child{
      border-bottom: none;
    }
    .name{
      color: $gray;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .id{
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .input_number{
      min-width: 100px;
    }
    .itemprice{
      margin-top: 10px;
      margin-bottom: 5px;
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .totalprice{
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .quantityWarning{
      margin-top: 10px;
      color: $redNotification;
      font-size: 14px;
    }
  }
}
