@import "../style/globals";

.accountSettings {
    .panel {
        .buttonWrapper {
            padding-left: 30px;
            padding-bottom: 30px;
        }
    }
}


@media(max-width: $mobileMaxWidth){
    .accountSettings {
        .row.gutter-20px {
            flex-direction: column;
            .col.w-66p {
                width: calc(100% - 20px);
            }
            .col.w-33p {
                width: calc(100% - 20px);
            }
        }
        .panel {
            .panelBody {
                padding: 25px 15px;
            }
            label.grid-135 {
                grid-template-columns: 85px auto;
                gap: 5px;
            }
            input {
                width: 100%;
            }
            .buttonWrapper {
                padding-left: 15px;
                padding-right: 15px;
                .button {
                    width: 100%;
                }
            }
        }
    }
}