@import "../style/globals";

.horizontalMenuWrapper{
  background-color: $darkBlue;
  transition: max-height .2s ease-in-out;
  .horizontalMenu{
    max-width: $desktopContentWidth;
    margin: 0 auto;
    display: flex;
    .menuElementWrapper{
      display: flex;
      flex-direction: column;
      &:first-child{
        margin-left: auto;
      }
      &:last-child{
        margin-right: auto;
        .menuElement{
          border-right: none;
        }
      }
      .menuElement{
        margin: 12px 0;
        padding: 3px 40px;
        font-family: $manrope;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        text-transform: uppercase;
        border-right: 1px solid $transparentSeparator;
        display: flex;
        span, img{
          margin: auto 0;
        }
        &:hover{
          background-color: $blue;
          border-radius: 4px;
          border-right-color: transparent;
        }
      }
    }
  }
}

@media(max-width: $mobileMaxWidth){
  .horizontalMenuWrapper{
    max-height: 10px;
    overflow: hidden;
    &.open{
      max-height: calc(100vh - 200px);
      overflow-x: hidden;
      overflow-y: auto;
    }
    .horizontalMenu{
      padding: 10px;
      flex-direction: column;
      .menuElementWrapper{
        width: 100%;
        margin: 15px 0 0 0;
        padding: 0 0 15px;
        border-bottom: 1px solid $transparentSeparator;
        &:last-child{
          border-bottom: none;
        }
        .menuElement{
          margin: auto;
          border-right: none;
        }
      }
    }
  }
}
