@import "../style/globals";

.sectionHeader {
  display: flex;
  justify-content: center;
  padding-bottom: 13px;
  margin-bottom: 30px;
  h1 {
    margin: auto 0;
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.content {
  font-size: 15px;
  color: $gray;
  word-break: break-word;
}

.transportServicesWrapper,
.services,
.transportWrapper,
.transport,
.transportInfo {
  display: flex;
}
.transportServicesWrapper {
  flex-direction: column;
  .servicesHeader{
    margin-bottom: 1rem;
  }
  .services {
    flex-direction: column;
    margin-bottom: 3rem;
    .servicesInfo{
      display: flex;
      flex-direction: row;
      width: 35%;
      margin-bottom: .5rem;
      img{
        flex: 1;
      }
      span{
        flex:8
      }
    }
  }
  .transportWrapper {
    flex-direction: column;
    align-items: flex-start;
    .transport {
      flex-direction: row;
      align-items: center;
      width: 60%;
      margin-bottom: 3rem;
      img {
        flex: 1;
      }
      .transportInfo {
        flex-direction: column;
        flex: 3;
        h4 {
          font-size: 15px;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

.transportAndServicesForm {
  padding: 3rem;
  background-color: $lightGray;
  border-radius: 8px;
  > .row {
    > .formField {
      input {
        width: 20rem;
      }
    }
  }
  .formButton {
    justify-content: center;
    > button {
      border-radius: 8px;
      padding: 1rem;
      background-color: $yellow;
      padding: 1.25rem 5rem;
      border-radius: 8px;
      border: none;
      font-family: Manrope;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $darkBlue;
    }
  }
  .formRow {
    display: flex;
    justify-content: space-between;
    > .formField {
      > label {
        display: inline-block;
        width: 6rem;
        flex-grow: 0;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #1b3294;
      }
      > input {
        flex-grow: 1;
        border-radius: 8px;
        border: none;
        padding: 0.75rem;
      }
    }
  }
  .formTextArea {
    display: flex;
    align-items: center;
    > label {
      display: inline-block;
      width: 6rem;
      flex-grow: 0;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #1b3294;
    }
    > textarea {
      flex-grow: 1;
      height: 10rem;
      border: none;
      resize: none;
      border-radius: 8px;
    }
  }
}
