@import "../style/globals";

footer.pageFooter{
  background-color: $darkBlue;
  color: $lighterBlue;
  padding: 25px;
  .footerContent{
    max-width: $desktopContentWidth;
    margin: 0 auto;
    padding: 0 35px;
    .horizontalMenuWrapper{
      margin: 0 -25px 30px;
      .menuElementWrapper{
        .menuElement{
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
    .copyrightWrapper{
      color: $lighterBlue;
      font-size: 13px;
      line-height: 1.15;
    }
  }
}

@media (max-width: $mobileMaxWidth) {
  footer.pageFooter{
    padding: 20px;
    .footerContent{
      padding: 0;
      .horizontalMenuWrapper{
        margin: 0 0 20px;
      }
    }
    .horizontalMenu{
      .menuElementWrapper{
        margin: 2px 0;
        padding: 0;
        border: none;
      }
    }
    .widget{
      &.text{
        width: 100% !important;
        text-align: center;
      }
    }
    hr{
      margin-left: -20px !important;
      margin-right: -20px !important;
    }
    .row.copyrightWrapper{
      flex-wrap: wrap;
      margin: 0;
      > .col{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        &:first-child{
          order: 5;
        }
        &:last-child{
          margin-top: 0;
        }
      }
    }
  }
}
