@import "../style/globals";


.contacts {
  .contactsWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    h3 {
      display: flex;
      gap: 12px;
      align-items: center;

      font-size: 20px;
      font-weight: 300;
    }
    .contactsInfoWrapper {
      width: 40%;
      max-width: 320px;
      .contactsInfo{
        height: 521px;
        background-color: $lightGray;
        border-radius: 8px;
        h3 {
          color: $darkBlue;
          font-size: 20px;
          padding: 20px 35px 0 35px;
          margin-bottom: 20px;
        }
        .item {
          display: flex;
          justify-content: space-between;
          padding: 6px 35px;


          .name {
            color: $darkBlue;
            font-size: 15px;
            font-weight: 500;
            .subName{
              font-size: 10px;
              color: $gray;
              font-weight: normal;
            }
          }
          .value {
            font-size: 13px;
            font-weight: 500;
            text-align: right;
            color: $gray;
            justify-content: center;
            span {
              display: block;
            }
          }


          &.ico {
            padding-top: 30px;
          }
          &.dic {
            padding-bottom: 20px;
            border-bottom: solid 1px $transparentBlue;
          }
          &.mail {
            padding-top: 20px;
          }
          &.sidlo {
            padding-bottom: 20px;
            border-bottom: solid 1px $transparentBlue;
          }
          &.seller1 {
            padding-top: 0;
          }
          &:last-child{
            padding-bottom: 30px;
          }

        }
      }
    }
    .contactsMapWrapper {
      min-width: 60%;
      width: calc(100% - 320px);
      iframe {
        border-radius: 8px;
        border: none;
      }
    }
  }


}

@media (max-width: $mobileMaxWidth) {

  .contacts {
    .contactsWrapper {
      flex-direction: column;
      h3 {
        justify-content: center
      }
      .contactsInfoWrapper {
        width: 100%;
        max-width: 100%;
      }
      .contactsMapWrapper {
        width: 100%;
      }
    }

  }

}
