@import "../style/globals";

.breadcrumbs{
  display: flex;
  
  &.hiddenDesktop {
    display: none;
  }

  >*{
    margin-top: auto;
    margin-bottom: auto;
  }
  .button{
    margin-right: 20px;
  }
  .categoryTree{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    color:$gray;
    flex-wrap: wrap;

    a{
      display: block;
      &:hover {
        color: $blueBackground;
      }
    }
  
    
  }
}

@media (max-width: $mobileMaxWidth) {
  .breadcrumbs{
    // flex-direction: column;
    display: none;

    &.hiddenDesktop {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .button {
        width: auto;
        padding: 12px 24px;
        max-height: 60px;
        margin-right: 0;
      }
    }
    

  }
}
