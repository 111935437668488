@import "../style/globals";

.orderDetailWrapper {
  position: absolute;
  background-color: $white;
  border-radius: 8px;
  border: solid 1px $darkerBlue;
  .header {
    background-color: $darkBlue;
    position: relative;
    padding: 5px 25px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    h2 {
      color: $white;
    }
    .button {
      &.close {
        position: absolute;
        right: 0;
        top: 0;
        &:before {
          content: ' ';
          position: relative;
          display: block;
          background-image: url("/staticImages/xClose.svg");
          height: 20px;
          width: 20px;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
  .container {
    padding: 25px;
    .row {
      justify-content: space-between;
      padding: 10px;
      border-bottom: solid 1px $offWhite;
    }

    strong {
      color: $darkBlue;
    }
  }


}
