@import "../style/globals";

.preloaderWrapper{
  position: relative;
  .preloader{
    &.dots{
      span{
        transition: all 500ms ease;
        background: $blue;
        box-shadow: 1px 2px 3px #999;
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 10px;
        animation: wave 2s ease infinite;

        $dots: 8;
        @for $i from 1 through $dots {
          &:nth-child(#{$i}){
            animation-delay: ($i - 1) * 100ms;
          }
        }
      }
      @keyframes wave{
        0%, 40%, 100% {
          transform: translate(0, 0);
          background-color: $blue;
        }
        10% {
          transform: translate(0, -15px);
          background-color: $darkRed;
        }
      }
    }

  }
}
