@import "../style/globals";

.eshop{
  padding-top: 0;
}

@media (max-width: $mobileMaxWidth) {
  .eshop.container > .row{
    flex-direction: column;
    flex-wrap: nowrap;
    .col.eshopContents{
      width: calc(100% - 20px);
    }
  }
}
