@import "../style/globals";

.w-75p > .product {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
.productInfo {
  display: flex;
  justify-content: space-between;
  height: max-content;
  .name {
    margin: 0 0 0.5rem 0;
  }
}

.product {
  h1 {
      margin: auto 0;
      font-size: 24px;
      font-weight: 300;
      text-transform: uppercase;
  }
  .productImages {
    display: flex;
    justify-content: space-between;
    .arrow-right {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 40px;
      right: 5%;
      &::after {
        background-image: url(/staticImages/arrowRightDarkerBlue.svg);
      }
    }
    .mainImage {
      height: 100%;
      position: relative;
      padding: 10px;
      border-radius: 8px;
      border: solid 1px $lightestBlue;
      display: flex;
      .zoom-in {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      img {
        margin: auto;
        width: auto;
        height: 300px;
      }
    }
  }
  .name {
    font-size: 17px;
    font-weight: 500;
  }
  .otherImages {
    height: 30%;
    .imageCarouselWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100% !important;
    }

    .inner {
      display: flex;
      padding: 0.5rem;
      margin: 1rem 0.5rem 0 0.5rem;
      img {
        width: auto;
        height: auto;
        border-radius: 8px;
      }
    }
  }
  .saleBlock {
    background-color: $lightGray;
    border-radius: 8px;
    padding: 15px;
    font-size: 13px;
    font-weight: 500;
    color: $gray;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    .saleBlockTop {
      display: flex;
      justify-content: space-between;
    }
    .button {
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      font-family: $manrope;
      span {
        margin: 0;
      }
      img {
        width: 25px;
        height: 20px;
        margin: 0 11px 0 0;
      }
    }

    .stockInfoAmount {
      color: $blue;
      &.warning{
        color: $redNotification;
      }
    }
    .prices {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
        flex-direction: row;
      }

      .amount-pre-sale {
        justify-content: space-between;
        .value {
          color: $darkRed;
          font-size: 13px;
          font-weight: 700;
          .amount {
            text-decoration-line: line-through;
          }
        }
      }
      .amount {
        justify-content: space-between;
        .value {
          color: $darkBlue;
          font-size: 13px;
          font-weight: 700;
        }
      }
      .amountDph {
        justify-content: space-between;
        .value {
          color: $darkBlue;
          font-size: 14px;
          font-weight: 800;
        }
      }
    }
    h2 {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .forDownload {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    .download {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.productDetail {
  .subtitle {
    margin: 0 0 25px 0;
    font-size: 15px;
    font-weight: 500;
    color: $darkBlue;
  }
  .description {
    margin: 0.5rem 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: $gray;
    word-wrap: break-word;
  }
}
.productOrder {
  flex-grow: 1;
  > div {
    padding: 0.5rem 0;
  }
  > .filterText {
    flex-direction: column;
    .input_filter_text > input {
      background-image: url(/staticImages/icons/searchGray.svg);
      background-repeat: no-repeat;
      background-position: right;
      background-position-x: 98%;
      position: relative;
    }
  }
  .buttons {
    justify-content: space-around;
    font-size: 15px;
    font-weight: 500;
    .active {
      background-color: $lightBlue;
      color: white;
    }
  }
}

.table-responsive {
  &.product-parameters {
    .table-content-item {
      grid-template-areas: "header value value value";
      grid-template-columns: repeat(4, 25%);
      & > div {
        padding: 10px 20px;
      }
    }
    .header {
      grid-area: "header";
      background-color: $lightGray;
      justify-content: flex-start;
    }
    .value {
      grid-area: "value";
      justify-content: center;
    }
    .table-content-item {
      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        & > div {
          &:first-child {
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: $mobileMaxWidth) {
  .product {
    h1 {
      font-size: 18px;
    }
    > .row{
      flex-direction: column;
      .col{
        width: calc(100% - 20px) !important;
      }
    }

    .table-responsive {
      &.product-parameters {
        .table-content-item {
          grid-template-areas: "header value";
          grid-template-columns: repeat(2, 50%);
        }
        .header {
          justify-content: center;
        }
      }
    }
    .forDownload {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // flex-wrap: wrap;
      // grid-template-columns: repeat(2, auto);
      // grid-column-gap: 0.5rem;
      // grid-row-gap: 0.5rem;
  
      .col{
        // width: 100% !important;
        display: inline-block;
        width: auto !important;
        .button {
          height: 100%;
          &.download {
            justify-content: space-around;
            width: max-content;
          }
        }
      }
    }
  }
}
