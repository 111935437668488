@import "../style/globals";

.eshopProductGrid {
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(32%, 31%));
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  @media(max-width: $mobileMaxWidth){
    grid-template-columns: 1fr;
  }
  .product {
    border-radius: 12px;
    box-shadow: 1px 3px 12px 0 rgba(54, 54, 89, 0.12);
    background-color: #ffffff;
    .productDescription {
      height: 6rem;
    }
    .image {
      padding: 15px;
      display: flex;
      height: 175px;
      width: 100%;
      transition: all 0.5s;
      &:hover {
        padding: 10px;
      }
      img {
        display: block;
        margin: auto;
        max-width: 100%;
        width: auto;
        // height: 100%;
      }
    }
    .name{
      padding: 0 15px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.07;
      text-align: center;
      color: $darkBlue;
      display: block;
      span{
        display: flex;
        flex-direction: column;
      }
    }
    .excerpt {
      margin: 10px 20px;
      font-size: 13px;
      line-height: 1.15;
      text-align: center;
      color: $gray;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 28px;
    }
    .price {
      margin: 0 15px;
      display: flex;
      font-family: $manrope;
      font-weight: 800;
      text-align: center;
      .amount-pre-sale {
        font-size: 15px;
        text-decoration: line-through;
        color: $darkRed;
        margin: auto 15px auto auto;
        & + .amount {
          margin-left: 0;
        }
      }
      .amount {
        margin: auto;
        font-size: 17px;
        color: $darkBlue;
      }
    }
    .productBuyStockInfo{
      display: flex;
      flex-direction: column;
      .stockInfo{
        order: 1;
        &.last{
          order: 10;
          margin: 0 10px 10px;
          color: $darkerGreen;
          font-weight: 500;
          font-size: 15px;
          text-align: center;
          display: block;
        }
      }
      .buyInfo{
        order: 2;
        display: flex;
        flex-direction: row;
        .input_number{
          margin: 10px;
          margin-right: 0 !important;
          min-width: auto;
          flex: 1;
          > span{
            margin: auto 5px auto 0;
          }
          .spin_buttons{
            margin: auto 0;
            .spin_top{
              margin-bottom: 2px;
            }
          }
        }
        .buyButton {
          &:hover {
            background-color: $lightBlueHover;
          }
        }
        @media(max-width: $mobileMaxWidth){
          flex-direction: column;
          .input_number{
            margin-right: 10px !important;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.eshopProductFlex {
  display: flex;
  flex-direction: column;
  .product-parameters {
    transition: all 0.5s;
  }
  .product {
    &.col{
      flex-direction: row;
    }

    border-radius: 12px;
    height: 8rem;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: $lightGray;
    margin-bottom: 1rem;

    .productDescription {
      height: 7rem;
      width: 15rem;
      display: flex;
      padding: 1rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      hr {
        display: none;
      }
      button {
        flex: 0 0;
        margin: 0 0 0rem 6rem;
        padding: 0.25rem 1rem;
        outline: none;
        border: none;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .productImage {
      background: $white;
      width: 13rem;
      .image {
        padding: 15px;
        display: flex;
        height: 7rem;
        width: 100%;
        transition: all 0.5s;
        &:hover {
          padding: 10px;
        }
        img {
          display: block;
          margin: auto;
          max-width: 100%;
          width: auto;
          height: 100%;
        }
      }
    }
    .name {
      padding: 0 15px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.07;
      text-align: center;
      color: $darkBlue;
      span{
        display: flex;
        flex-direction: column;
      }
    }
    .excerpt,
    .id {
      display: none;
    }
    .price {
      margin: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8rem;
      flex-direction: column;
      font-family: $manrope;
      font-weight: 800;
      background-color: $whisper;
      .amount-pre-sale {
        font-size: 15px;
        text-decoration: line-through;
        color: $darkRed;
        & + .amount {
          margin-left: 0;
        }
      }
      .amount {
        font-size: 17px;
        color: $darkBlue;
      }
    }
    .productBuyStockInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20rem;
      padding: 1rem;
      .stockInfo {
        text-align: end;
        font-weight: 500;
        font-size: 14px;
        padding-top: 0;
        .onStock {
          color: $darkerGreen;
        }
        .offStock {
          color: $darkRed;
        }
      }
      .buyInfo {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .buyAmount {
          min-width: 35%;
        }
      }
    }
    .viewFlexBtn{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .button {
        margin: 0 .5rem;
        flex: 1;
        padding: 0.25rem 1rem;
        align-items: center;
      }
      a{
        margin: 0 .5rem;
      }

    }
  }
}

.categoryPagination {
  border-radius: 8px;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    padding-inline-start: 0 !important;
    .selected {
      border-radius: 8px;
      background-color: $lightBlue;
      color: $white;
    }
    > li {
      list-style-type: none !important;
      color: $blue;
      background-color: $lightGray;
      padding: 0.5rem;
      border-radius: 8px;
      margin: 0 0.25rem;
      text-align: center;
      >a{
        min-width: 1.2rem;
        display: block;
      }

      &:hover {
        background-color: $lightBlueHover;
      }

      &.previous,
      &.next,
      &.selected {
        &:hover {
          background-color: $blue;
        }
      }

    }
    .previous,
    .next {
      color: $white;
      background-color: $lightBlue;
      width: 12rem;

    }

    .previous {
      margin-right: 1rem;
    }
    .next {
      margin-left: 1rem;
    }


  }
}


@media(max-width: $mobileMaxWidth){
  .categoryPagination{
    ul{
      li{
        display: none;
        &.previous, &.next, &.selected{
          display: block;
        }
      }
      .previous{
        margin-right: auto;
      }
      .next{
        margin-left: auto;
      }
      .selected{
        color: #5d5edf;
        background-color: #f2f3f5;
      }
    }
  }
}
