@import "../style/globals";

.row.advantages {
    gap: 20px;
}

@media(max-width: $mobileMaxWidth){
    .row.advantages {
        flex-direction: column;
    }
}