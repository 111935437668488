@import "../style/globals";

.postExcerpt {
  cursor: pointer;
  position: relative;
  padding: 30px 25px 25px 30px;
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0 rgba(54, 54, 89, 0.12), inset 1px 1px 0 0 #ffffff;
  min-height: 17rem;
  width: 20rem;

  &:after {
    content: " ";
    display: block;
    background-image: url("/staticImages/roh.svg");
    position: absolute;
    bottom: 0;
    right: 0;
    width: 38px;
    height: 38px;
  }

  h3 {
    font-size: 20px;
    font-weight: 300;
    font-family: $roboto;
    margin: 0 0 5px 0;
    text-transform: none;
  }
  .createdAt {
    font-size: 11px;
    font-weight: bold;
    color: $green;
  }
  .excerpt {
    height: inherit;
    max-height: inherit;
    max-height: 105px;
    overflow: hidden;
  }
}
@media(max-width: $mobileMaxWidth){
  .postExcerpt{
    width: 100%;
  }
}
