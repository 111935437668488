@import "../style/globals";

.notificationsWrapper {
  position: fixed;
  display: block;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1000px;
  z-index: 10;
}
.notificationWrapper {
  display: block;
  margin-bottom: 10px;
  width: 100%;


  &.hide {
    display: none;
  }

  .notification {
    padding: 15px 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;

    &.error {
      background-color: $redNotification;
    }
    &.warning {
      background-color: $yellowNotification;
    }
    &.info {
      background-color: $blueNotification;
    }
    &.success{
      background-color: $darkerGreen;
    }
    .notification_button {

      &:after {
        content: ' ';
        display: block;
        height: 20px;
        width: 20px;
        background-image: url("/staticImages/xClose.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

    }
    .notification_icon {
      &:before {
        content: ' ';
        display: block;
        height: 20px;
        width: 22px;
        background-image: url("/staticImages/notification.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .notification_alert {
      width: 100%;
    }
  }
}
