@import '../style/globals';

.cookiesBar{
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 1rem 2rem;
  background: rgba($darkBlue, 0.9);
  align-items: center;
  .cookiesText{
    color: $whisper;
    a{
      margin-left: 5px;
      text-decoration: underline;
    }
  }
  .cookiesButton{
    margin-left: auto;
  }
}
