@import "../style/globals";

.categoryFilter {
  .sortProductsWrapper{
    // background-color: $lightGray;
    border-radius: 8px;
  }
  .filterTitle {
    font-size: 15px;
    font-weight: 500;
    color: $darkBlue;
    margin-bottom: 0;
  }
  > .row {
    margin-bottom: 1rem;
  }
  .button {
    border: none;
  }
  .buttons {
    .button {
      background-color: $lightGray;
      &.medium {
        border-radius: 0;
      }
      &.active {
        background-color: $blueBackground;
      }
      &:not(.active) {
        &:hover {
          background-color: $lightBlueHover;
        }
      }
      
      margin-right: 2px;
      align-items: center;
    }
    .button:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .button:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-right: 0px;
    }
  }
  .input_filter_text {
    input[type="text"] {
      border: solid 1px #d5dcf4;
      border-radius: 8px;
      padding: 0.7rem;
      width: 100%;
    }
  }

  .productDistributor {
    > .filterTitle {
      padding-bottom: 1rem;
    }
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 1rem;
    border-radius: 8px;
    background-color: #f2f3f5;
    margin-bottom: 1rem;
    img {
      width: 50%;
    }
  }

  .sortingTags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filterView {
      display: flex;
      justify-content: center;
      align-items: center;
      button{
        margin: 0 .5rem;
      }
      .active{
        background-color: $lightBlue;
      }
    }

  }

  .productOrder {
    padding-left: .75rem;
  }

  .productPriceRange {
    flex-grow: 1;
    padding-left: 1rem;
    justify-content: space-between;
    > div {
      padding: 0.5rem 0;
    }
  }

  .priceInterval {
    min-height: 40px;
    font-size: 15px;
    color: $blue;
    display: flex;
    border-radius: 8px;
    .priceFrom,
    .priceTo {
      display: flex;
      width: 6rem;
      justify-content: center;
      background-color: white;
      align-items: center;
      margin: 0 0.5rem;
      border-radius: 8px;
      border: 1px solid $lightestBlue;
    }
  }
  .priceIntervalSlider {
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    min-height: 40px;
    font-size: 15px;
    font-weight: 500;
    padding: 12px;
    border-radius: 8px;
    align-items: center;
    width: 100%;
    background-color: $lightGray;
  }
  .factories {
    display: flex;
    flex-wrap: wrap;
    .factoryBtn {
      border: none;
      font-size: 12px;
      font-weight: 600;
      color: $blue;
      &.arrow-right {
        background-image: url("/staticImages/arrowRightBlue.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-position-x: 90%;
      }
      &.arrow-left {
        background-image: url("/staticImages/arrowLeftBlue.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-position-x: 10%;
      }
      &:hover {
        background-color: $lightBlueHover;
      }
    }
  }

  .factory {
    cursor: pointer;
    margin: 0 0 15px 0;
    border-radius: 8px;
    box-shadow: 2px 4px 12px 0 #b1b1b7;
    align-items: center;
    background: white;
    display: flex;
    width: 18%;
    height: 4rem;
    margin: 0.43rem;
    justify-content: center;
    input[type="checkbox"] {
      margin: 0.5rem;
    }
    &.arrow-left {
      background-image: url("/staticImages/arrowRightBlue.svg");
    }

  }

  .input-range__label--min,
  .input-range__label--max,
  .input-range__label--value {
    display: none;
  }
  .input-range {
    height: 1rem;
    padding: 1rem;
    position: relative;
    width: 75%;
  }
  .input-range__track {
    background: $sliderGray;
  }
  .input-range__track--active {
    background: $lightBlue;
  }

}


@media (max-width: $tabletMaxWidth){
  .eshopProductGrid {
    .product {
      .price {
        flex-direction: column;
        .amount-pre-sale{
          // display: none;
          text-align: center;
          margin: auto;
          + .amount {
            margin-left: auto;
          }
        }
        .amount{
          text-align: center;
          margin-left: auto;
        }
        
      }
      
    }  
  }
}


@media (max-width: $mobileMaxWidth){
  .categoryFilter{
    .filterTitle{
      font-size: 15px;
      font-weight: 500;
      width: 100%;
    }
    .sortProductsWrapper{
      flex-direction: column;
      padding: 0;
      background: none;
      border-radius: 0;
      .productOrder{
        padding: 0;
        margin-bottom: 10px;
        .col{
          padding: 0;
          &.flex-grow-1 {
            flex-grow: 1;
          }
        }
        select{
          padding: 12px 45px 12px 15px;
          font-size: 14px;
          font-weight: 500;
          color: $blue;
          font-family: $roboto;
          border-radius: 8px;
          background-color: $lightGray;
          border: none;
          appearance: none;
          background-image: url("/staticImages/caretBlueDown.svg");
          background-position: 93% 50%;
          background-repeat: no-repeat;
        }
      }
      .priceInterval{
        border-radius: 8px;
        background-color: $lightGray;
        padding: 11px;
        text-align: center;
        display: block;
        margin-bottom: 10px;
      }
      .priceIntervalSlider{
        border-radius: 8px;
        background-color: $lightGray;
        padding: 0.2rem 0 0 0;
      }
    }


    .productDistributor{
      padding: 15px;
      .filterTitle{
        text-align: center;
      }
      .factories{
        margin: -5px;
        flex-direction: row;
        justify-content: center;
        .factory{
          width: 130px;
          margin: 5px;
          // background: none;
          box-shadow: none;
          border: solid 1px $lightestBlue;

        }
      }
    }

  }
  .eshopCategoryWrapper{
    .eshopProductGrid{
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      .product{
        .image{
          // height: 70px;
          height: 128px;
        }
        .productDescription{
          height: auto;
          flex:1;
          .name{
            font-size: 13px;
            font-weight: 500;
          }
        }
        .price{
          flex-direction: column;
          .amount-pre-sale{
            // display: none;
            text-align: center;
            margin: auto;
          }
          .amount{
            margin-left: auto;
          }
        }
      }
    }
  }
}
