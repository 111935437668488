@import "../style/globals";

.manufacturerListing{
  .button{
    margin-top: 30px;
  }
  .manufacturers{
    margin: -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .manufacturer{
      display: flex;
      flex-shrink: 0;
      margin: 10px;
      height: 75px;
      width: 75px;
      &:hover{
        box-shadow: $pillShadow;
      }
      img{
        margin: auto;
        width: 100%;
        max-height: 100%;
      }
    }
  }
}

@media (max-width: $mobileMaxWidth){
  .manufacturerListing{
    padding: 10px;
    .box{
      padding: 20px;
      box-shadow: none;
      background: $lightGray;
    }
    .manufacturers{
      margin: -8px;
      .manufacturer{
        margin: 8px;
        img{
          mix-blend-mode: multiply;
        }
      }
    }
  }
}
