@import "../style/globals";

.formWrapper {
    
    .panel {
        .panelHeader{
          padding: 25px 20px;
          h3 {
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 300;
            align-items: center;
            display: flex;
            gap: 10px;
          }
        }
        .panelBody {
          form {
            display: grid;
            column-gap: 20px;
            row-gap: 20px;
            grid-template-columns: 1fr 1fr;
    
            label {
              grid-template-columns: 80px auto;
              
              &.process_textarea {
                grid-column-start: 1;
                grid-column-end: 3;
              }
              &.process_lastname {
                span {
                  text-align: right;
                  padding-right: 14px;
                }  
              }
              &.process_email {
                span {
                  text-align: right;
                  padding-right: 14px;
                }  
              }
            }
            input {
              width: 100%;
            }
            textarea {
              width: 100%;
            }
            .buttonWrapper {
              grid-column-start: 1;
              grid-column-end: 3;
              button {
                display: block;
                margin: auto;
                background-color: $yellow;
                font-size: 14px;
                font-weight: bold;
                color: $darkerBlue;
                border-radius: 8px;
                border: none;
                padding: 16px 52px;
              }
              .icon {
                vertical-align: middle;
                padding-left: 11px;
              }
            }
          }
        }
      }
}

@media (max-width: $mobileMaxWidth) {
    .formWrapper {
        .panel {
            .panelHeader {
              h3 {
                justify-content: center
              }
            }
            .panelBody {
              padding: 20px 15px;
              form {
                grid-template-columns: 1fr;
                row-gap: 15px;
                label {            
                  &.process_textarea {
                    grid-column-start: 1;
                    grid-column-end: 2;
                  }
                  &.process_lastname {
                    span {
                      text-align: left;
                    }  
                  }
                  &.process_email {
                    span {
                      text-align: left;
                    }  
                  }
                }
                .buttonWrapper {
                  grid-column-start: 1;
                  grid-column-end: 2;
                  button {
                    width: 100%;
                  }
                }
              }
            }
          }
    }
}