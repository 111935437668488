@import "../style/globals";
header.pageHeader {
  background-color: $lightGray;
  position: fixed;
  z-index: 4;
  width: 100%;
  margin-bottom: 20px;
  .headerControls {
    max-width: $desktopContentWidth;
    margin: 0 auto;
    padding: 10px 0;
    display: flex;
    .logoContainer {
      margin: auto;
      .logo {
        max-width: 81px;
        max-height: 77px;
      }
    }
    .userControls {
      margin: auto;
      display: flex;
      .button {
        padding: 15px 16px 14px 17px;
        span {
          font-family: $manrope;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          color: $darkBlue;
          margin-right: 5px;
        }
        &:hover {
          background-color: $lightBlueHover;
        }
      }
      img.icon {
        width: 100%;
      }
      .button img.icon {
        width: auto;
      }
      .admin {
        // margin-right: 30px;
      }
      .account {
        // margin-right: 30px;
      }
    }
    .searchListBackground{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.1);
      z-index: 1;
    }
    .controlBox {
      margin: auto;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 4px 4px 8px 0 $lightShadow;
      background-color: $white;
      display: flex;
      height: 53px;
      .preloaderWrapper{
        margin: auto 0;
      }
      .searchList {
        position: absolute;
        top: 60%;
        width: 508px;
        max-width: calc(100vw - 20px);
        max-height: calc(100vh - 160px);
        display: flex;
        flex-direction: column;
        padding: 25px;
        border-radius: 8px;
        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        .linkName{
          margin-right: 5px;
        }
        .searchCategoryCol{
          display: flex;
          flex-direction: column;

          .searchCategoryItem{
            margin-bottom: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid $lightGray;
            &:nth-child(n + 3){
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }
            &:nth-child(n + 4){
              max-height: 0;
              overflow: hidden;
              transition: all .5s ease-in-out;
            }
            &:last-of-type{
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }
          }
          .showMore{
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
            display: none;
            span{
              margin: 0 15px;
            }
            .icon{
              transition: transform .5s ease-in-out;
            }
            &:nth-child(n+4){
              display: flex;
            }
          }
          &.open{
            .searchCategoryItem{
              &:nth-child(n+3){
                margin-bottom: 5px;
                padding-bottom: 5px;
                border-bottom: 1px solid $lightGray;
              }
              &:nth-child(n + 4){
                max-height: 100px;
                overflow: hidden;
              }
              &:last-of-type{
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
              }
            }
            .showMore{
              .icon{
                transform: rotate(180deg);
              }
            }
          }
        }
        .content{
          flex: 1;
          overflow: auto;
        }
        .closeButton{
          position: absolute;
          top: 13px;
          right: 15px;
          height: 20px;
          width: auto;
          cursor: pointer;
        }
        h3 {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          color: #898989;
          margin: 20px 0;
        }
        .button {
          border: none;
          border-radius: 8px;
          padding: 15px 30px;
          background-color: #f2f3f5;
          margin: 0;
          flex-shrink: 0;
        }
        .searchItem {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1% 0;
          .imgWrapper {
            display: flex;
            justify-content: center;
            width: 15%;
            img {
              width: auto;
              height: 50px;
            }
          }
          span {
            display: flex;
            width: 80%;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b3294;
            margin-left: 5%;
          }
        }
        .searchCategoryItem {
          span {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #5d5edf;
          }
          .caret-right {
            background-image: url("/staticImages/pathCaretRightBlue.svg");
            padding-left: 1rem;
            background-repeat: no-repeat;
            background-position: left;
          }
        }
      }
      .separator {
        width: 1px;
        height: 34px;
        margin: auto 20px;
        background-color: $blueTransparentSeparator;
      }
      .button {
        margin: auto 20px auto 0;
        img {
          width: 20px;
          max-height: 20px;
        }
      }
      &.search {
        flex-basis: 50%;
        z-index: 2;
        .searchInput {
          height: 100%;
          border: none;
          font-size: 14px;
          font-weight: 500;
          color: $gray;
          padding: 19px 26px;
          flex: 1;
          &::placeholder {
            color: $gray;
            opacity: 0.8;
          }
        }
      }
      &.cart {
        margin-right: 0;
        font-family: $manrope;
        font-size: 14px;
        font-weight: bold;
        color: $darkBlue;
        .button {
          margin: 15px 10px 15px 15px;
          position: relative;
          img {
            width: 25px;
          }
        }
        .separator {
          margin-left: 0;
          margin-right: 0;
        }
        .total {
          margin: auto 15px auto 10px;
        }
        .cartSize {
          color: $white;
          background: $darkRed;
          border-radius: 100%;
          line-height: 19px;
          font-family: $manrope;
          font-size: 11px;
          font-weight: bold;
          position: absolute;
          top: -9px;
          right: -9px;
          width: 20px;
          height: 20px;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: $mobileMaxWidth) {
  header.pageHeader{
    .headerControls{
      padding: 10px;
      flex-wrap: wrap;
      .logoContainer{
        order: 1;
        margin: 2px 10px 10px 20px;
        .logo{
          max-width: 64px;
        }
      }
      .controlBox{
        height: 48px;
        .button{
          margin: auto 15px;
        }
        &.cart{
          order: 3;
          margin-left: 0px;
          margin-right: 9px;

          .button{
            margin: auto 15px;
          }
          .separator {
            display: none;
          }
          .total{
            display: none;
          }
        }
        &.burgerMenu{
          order: 5;
          margin-right: 0;
          margin-left: auto;
          &.blue {
            background-color: #5d5edf;
          }
        }
        &.search{
          order: 2;
          flex-basis: 70%;
          .button{
            min-width: 20px;
            margin: auto;
          }
          padding-right: 20px;
        }
      }
      .controlBoxMobile{
        margin: auto;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 4px 4px 8px 0 $lightShadow;
        background-color: $white;
        display: flex;
        height: 48px;
        .button {
          margin: auto 15px;
          img {
            width: 20px;
            max-height: 20px;
          }
        }
      }
      .userControls{
        order: 4;
        margin-left: 0;
        margin-right: 9px;
      }
    }
  }
}
