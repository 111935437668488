@import "../style/globals";
.container{

  .pageHeader {
    width: 100%;
    padding-bottom: 13px;
    border-bottom: solid 1px #d9d9d9;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1b3294;
  }
}

.order {
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 8px;
    background-color: $lightGray;
    padding: 2%;
  }
  .subscriber,
  .deliveryAddress {
    margin-right: 1%;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #1b3294;
    .light-weight-header {
      border-bottom: solid 1px #d9d9d9;
      padding: 0 0 15px 0;

      margin: 0 0 15px 0;
    }
  }
  .deliveryAddress,
  .orderInfo {
    margin-left: 1%;
  }

  .orderInfo {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #1b3294;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .createdAt,
    .paymentAt {
      border-bottom: solid 1px #d9d9d9;
      padding: 0 0 10px 0;
      margin-bottom: 10px;
    }
    .light-weight-header {
      border-bottom: solid 1px #d9d9d9;
      padding: 0 0 15px 0;

      margin: 0 0 15px 0;
    }
  }
}

.light-weight-header {
  span {
    text-transform: uppercase;
  }
  margin: 3% 0 3% 2%;
}
.table-responsive {
  &.thankyou {
    .table-header,
    .table-content-item {
      grid-template-areas: "name quantity itemprice baseprice dph totalprice";
      grid-template-columns: 3fr 1fr 1fr 1fr 1fr 2fr;
      & > div {
        word-break: break-word;
        padding: 18px;
        justify-content: center;
      }
      > div:first-child {
        justify-content: flex-start;
      }
    }
    .name {
      grid-area: name;
      justify-content: flex-start;
      text-align: left;
      word-break: break-all;
    }
    .quantity {
      grid-area: quantity;
    }
    .itemprice {
      grid-area: itemprice;
    }
    .totalprice {
      grid-area: totalprice;
    }

    .table-footer {
      grid-template-columns: repeat(6, 16.6%);
      align-items: center;
      .button {
        border-radius: 8px;
      }

      .table_total_price {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid $offWhite;
        //border-radius: 8px;

        &.table_total_price_label {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          span {
            color: $darkBlue;
            font-size: 15px;
            font-weight: 500;
            padding: 17px;
          }
        }
        &.table_total_price_number {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-left: none;
          span {
            color: $darkBlue;
            font-size: 17px;
            font-weight: 800;
            padding: 16px;
          }
        }
      }
    }
  }
}
.table-responsive {
  &.footer {
    margin: 3% 0 0 33%;
    .table-header,
    .table-content-item {
      grid-template-areas: "infoDPH withoutDPH withDPH totalprice";
      grid-template-columns: 3fr 1fr 1fr 1fr;
      & > div {
        word-break: break-word;
        padding: 18px;
        justify-content: center;
      }
      > div:first-child {
        justify-content: flex-start;
        text-align: left;
      }
    }
    .infoDPH {
      justify-content: flex-start;
      grid-area: infoDPH;
      word-break: break-all;
    }
    .withoutDPH {
      grid-area: withoutDPH;
    }
    .withDPH {
      grid-area: withDPH;
    }
    .totalprice {
      grid-area: totalprice;
    }
  }
}

.payment {
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  background-color: #f2f3f5;
  margin-top: 3%;
  .light-weight-header {
    border-bottom: solid 1px #d9d9d9;
    padding: 20px 0;
    margin: 0;
  }
  .bankTransferWrapper {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.36;
    letter-spacing: normal;
    color: #1b3294;
    .bankAccount {
      display: flex;
      flex-direction: column;
      flex: 2;
      margin: 0 5px;
      label {
        padding: 20px 0;
        text-align: left;
      }
      div {
        text-align: left;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 15px;
        border-radius: 8px;
        background-color: white;
      }
    }
    div {
      display: flex;
      flex-direction: column;
      flex: 1;
      text-align: center;
      margin: 0 5px;
      label {
        padding: 20px 0;
      }
      div {
        justify-content: center;
        padding: 34px;
        border-radius: 8px;
        background-color: white;
      }
    }
    .totalPrice {
      margin-right: 0;
      div {
        margin-right: 0;
        font-size: 23px;
      }
    }
    .accountInfo,
    .bankAccount {
      margin-left: 0;
    }
  }
}
