@import "../style/globals";

@media(max-width: $mobileMaxWidth){
    .verify {
        .pageForm {
            flex-direction: column;
            .col.w-66p {
                width: 100%;
            }
            .col.w-33p {
                width: 100%;
            }
        }
        .panel {
           input {
               width: 100%
           }
           .button {
               width: 100%;
           }
        }
        
    }
}
