@import '../style/globals';

.rent_formular {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 15px;

    label {
        display: block;
        padding: 10px;
        border-radius: 8px;
        background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(100deg, #f2f3f5 5%, #fff);
        &.rent_calendar {
            padding-right: 0;
        }
    }
    textarea {
        width: 100%;
        max-width: 100%;
        min-height: 4em;
        display: block;
        border: none;
        background-color: transparent;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-family: $roboto;
            color: $darkBlue;
            opacity: 1; /* Firefox */
            font-weight: bold;
          }
          
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: $roboto;

            color: $darkBlue;
            font-weight: bold;
        }
          
        &::-ms-input-placeholder { /* Microsoft Edge */
            font-family: $roboto;

            color: $darkBlue;
            font-weight: bold;
        }
    }
    input {
        font-size: 13px;
        font-weight: 500;
        width: 100%;
        display: block;
        color: $darkBlue;
        border: none;
        background-color: transparent;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-family: $roboto;

            color: $darkBlue;
            opacity: 1; /* Firefox */
            font-weight: bold;
          }
          
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: $roboto;

            color: $darkBlue;
            font-weight: bold;
        }
          
        &::-ms-input-placeholder { /* Microsoft Edge */
            font-family: $roboto;

            color: $darkBlue;
            font-weight: bold;
        }
    }
}
