@import "../style/globals";

@import "../style/globals";

.stepsWithIconsWrapper {
    background-color: $lightGray;
    border-radius: 8px;
    .stepsWithIconsHeader {
        padding: 25px 35px 4px 35px;
        border-bottom: solid 1px $transparentBlue;
        h3 {
            font-size: 20px;
            font-weight: 300;
            color: $darkBlue;
        }
        
    }
    .stepsWithIconsBody {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 86px;
        padding: 35px 45px 31px 45px;
        .step {
            
            // text-align: center;
            flex: 1 1 0;
            position: relative;
            font-size: 13px;
            color: $gray;
            
            
            &::after {
                content: ' ';
                display: block;
                position: absolute;
                top: 50%;
                transform: translate(0, -100%);
                right: -56px;
                width: 26px;
                height: 24px;
                background-image: url('/staticImages/processArrow.svg');
            }
            &.last {
                &::after {
                    content: none;
                }
            }
            
        }
    }
}

@media(max-width: $mobileMaxWidth){
    .stepsWithIconsWrapper {
        .stepsWithIconsBody {
            flex-direction: column;
            gap: 43px;
            .step {
                max-width: 163px;
                &::after{ 
                    position: relative;
                    transform: rotate(90deg);
                    top: 21px;
                    left: 68.5px;
                }
            }    
        }
    }
}
