// Global fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
$roboto: "Roboto", sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800&display=swap');
$manrope: "Manrope", sans-serif;

// Global colours
$offWhite: #d9d9d9;
$lightGray: #f2f3f5;
$sliderGray: #c2cbeb;
$gray: #898989;
$whisper: #eaeaea;
$darkerBlue: #363659;
$darkBlue: #1b3294;
$separatorBlue: #4663d4;
$blue: #5d5edf;
$blueBackground: #617ef3;
$lightBlue: #6f87eb;
$lighterBlue: #4adaf8;
$lightestBlue: #d5dcf4;
$lightBlueHover: #e2e6ef;
$darkRed: #cc0000;
$green: #aacc00;
$darkerGreen: #2d9c1e;
$transparentWhite: rgba(255,255,255,0.1);
$transparentSeparator: rgba(255, 255, 255, 0.3);
$transparentBlue: rgba(97, 126, 243, 0.2);
$blueTransparentSeparator: rgba(27, 50, 148, 0.45);
$white: #fff;
$lightShadow: rgba(0, 0, 0, 0.1);
$transparentHr: rgba(0, 0, 0, 0.15);
$grayShadow: 1px 4px 12px 0 rgba(54, 54, 89, 0.12);
$pillShadow: 2px 4px 12px 0 rgba(54, 54, 89, 0.15), inset 1px 1px 0 0 #ffffff;
$boxShadow: 1px 3px 12px 0 rgba(54, 54, 89, 0.12);
$yellow: #f6cb17;
$yellowNotification: #e1c345;
$redNotification: #cf3131;
$blueNotification: #8897d7;

// Global backgrounds
$grayWhiteGradientBackground: linear-gradient(132deg, #f2f3f5 3%, #ffffff);

// Global sizes
$desktopContentWidth: 1000px;
$desktopMinScreenWidth: 1100px;

// Global breakpoints
$tabletMaxWidth: 1024px;
$mobileMaxWidth: 768px;
