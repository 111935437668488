@import "../style/globals";

.profile{
  .navigation {
    gap: 10px;
  }
  .gdpr{
    padding: 25px 35px;
    border-radius: 8px;
    border: solid 1px $offWhite;
  }
  h3, p{
    color: $gray;
  }
  h3{
    margin-bottom: 15px;
  }
  p{
    margin-top: 0;
    margin-bottom: 5px;
  }

}

@media(max-width: $mobileMaxWidth){
  .profile{
    .navigation {
      flex-direction: column; 
      gap: 10px;

    }
  }
}