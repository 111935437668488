@import "../style/globals";

.content {
  font-size: 15px;
  color: $gray;
  word-break: break-word;
}

.row.banners {
  @media(max-width: $mobileMaxWidth){
    margin: 0 !important;
    flex-direction: column;
    gap: 15px;
  }
  .col.banner {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    .row .col.w-40p {
      &.bottom-right-arrow {
        &::before {
          content: none;
        }
        &::after {
          content: none;
        }
      }
    }

    @media (max-width: $mobileMaxWidth){
      background-color: transparent !important;
      // background-image: none !important;
      width: 100% !important;
      max-width: 300px;
      // max-height: 210px;
      margin: auto;
     
    }
    &.white {
      .col {
        padding-top: 20px;
      }
      h3,
      p {
        color: $white;
      }
      h3 {
        text-transform: capitalize;
        font-size: 26px;
        margin-bottom: 2px;
      }
    }
    &.small-header {
      min-height: 250px;
      h3 {
        font-size: 20px;
        text-align: center;
      }
    }
    .inner-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
    
    .col {
      position: relative;
      z-index: 1;
      padding: 25px 35px;
      &.cards {
        margin-left: auto;
        padding: 15px;
        z-index: 3;
        @media (max-width: $mobileMaxWidth){
          padding: 0;
          width: 100%;
        }
        .card {
          margin-bottom: 15px;
          @media(max-width: $mobileMaxWidth){
            margin: 0 10px 10px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    h3 {
      font-size: 33px;
      font-weight: 300;
      color: $darkBlue;
      margin: 0 0 10px 0;
      text-transform: none;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.13;
      color: $gray;
      margin: 0;
    }
    .button {
      &.bottom {
        position: absolute;
        bottom: 5%;
        left: 5%;
        right: 5%;
        border-radius: 8px;
      }
      &.full-width {
        width: 90%;
      }
    }
  }
  //TODO: TEMP
  &.sidebar {
    flex-wrap: wrap;
    .banner {
      flex-basis: 100% !important;
    }
  }
}

.bannersServices {

  .banner {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    flex:1;
    &.white {
      .col {
        padding-top: 20px;
      }
      h3,
      p {
        color: $white;
      }
      h3 {
        text-transform: capitalize;
        font-size: 26px;
        margin-bottom: 2px;
      }
    }
    &.small-header {
      min-height: 250px;
      h3 {
        font-size: 20px;
        text-align: center;
      }
    }
    .inner-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
    .col {
      position: relative;
      z-index: 1;
      padding: 25px 35px;
      &.cards {
        margin-left: auto;
        padding: 15px;
        z-index: 3;
        .card {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    h3 {
      font-size: 33px;
      font-weight: 300;
      color: $darkBlue;
      margin: 0 0 10px 0;
      text-transform: none;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.13;
      color: $gray;
      margin: 0;
    }
    .button {
      &.bottom {
        position: absolute;
        bottom: 5%;
        left: 5%;
        right: 5%;
        border-radius: 8px;
      }
      &.full-width {
        width: 90%;
      }
    }
  }
  .bannerText{
    display: flex;
    flex:2;
    align-items: center;
  }
  .bannerTextLi{
    display: flex;
    flex-direction: column;
    flex:2;
    align-items: flex-start;
    justify-content: center;
  }
  .left{
    padding-right: 3rem;
  }
  .right{
    padding-left: 3rem;
  }
  //TODO: TEMP
  &.sidebar {
    flex-wrap: wrap;
    .banner {
      flex-basis: 100% !important;
    }
  }
}

@media (max-width: $mobileMaxWidth){
  .row.bannersServices {
    flex-direction: column;
    .col.banner {
      order: 0;
      &.w-33p {
        width: 100%;
        max-width: 300px;
        margin: auto;
        .col {
          position: inherit;
        }
      }
    }
    .bannerText {
      order: 1;
      padding: 0;
    }
    .bannerTextLi {
      padding: 0;
    }
  }

  .homePage {
    .row.banners {
      .col.banner {
        background-image: none !important;
        .row {
          flex-direction: column;
          .col.w-40p {
            flex-direction: row;
            width: calc(100% - 20px);
            // padding: 0;
            margin: 0 10px 10px;
            padding: 15px;
            border-radius: 8px;
            box-shadow: $pillShadow;
            background-image: linear-gradient(122deg, #eff0f4 4%, #ffffff);
            h3 {
              text-align: center;
              font-size: 24px;
              font-weight: 300;
              margin: 0;
              display: flex;
              align-items: center;
              gap: 15px;
              width: 100%;
              &::before {
                content: ' ';
                background-image: url('/staticImages/icons/truck.svg');
                background-size: cover;
                width: 38px;
                height: 30px;
              }
            }
            
            p {
              display: none;
            }
            .button {
              display: none;
            }

            &.bottom-right-arrow {
              &::before {
                content: ' ';
              }
              &::after {
                content: ' ';
              }
            }
          }
          .col.cards {
            .card {
              display: flex;
              align-items: center;
              gap: 15px;
              img {
                order: 0;
              }
              h4 {
                order: 1;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
