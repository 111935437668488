@import "../style/globals";

.registration {

}

@media(max-width: $mobileMaxWidth){
    .registration {
     .row.pageContent {
         gap: 20px;
         flex-direction: column;
         .col.w-66p {
            width: 100%;
         }
         .col.w-33p {
            width: 100%;
         }
     }
    }
    .buttonWrapper {
        .button {
            width: 100%;
        }
    }
}