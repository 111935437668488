@import "../style/globals";

.newsPost {
  position: relative;

  h1 {
    font-weight: 300;
    font-family: $roboto;
    margin: 0 0 5px 0;
    text-transform: none;
  }
  .createdAt {
    font-size: 11px;
    font-weight: bold;
    color: $green;
  }
  .content {
    color: $gray;
    font-size: 13px;
    font-weight: normal;
  }
  .images {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    .image {
      width: 320px;
      height: 210px;
      margin: 25px 20px 25px 1px;
      border-radius: 8px;
      box-shadow: inset 1px 1px 0 0 #ffffff;
      background-image: linear-gradient(to bottom, #f2f3f5, #f2f3f5),
        linear-gradient(146deg, #f2f3f5 3%, #ffffff 75%);
    }
  }
}

@media (max-width: $mobileMaxWidth) {
  .breadcrumbs{
    width: 100%;
    .button{
      width: 100%;
    }
  }
  .newsPost{
    .images{
      grid-template-columns: repeat(1, auto);
      .image{
        width: 100%;
      }
    }
  }
}
