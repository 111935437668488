@import "globals";

* {
  box-sizing: border-box;
}

body {
  font-family: $roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}



img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
  &.icon {
    width: auto;
  }
}
a,
button,
.button {
  cursor: pointer;
  &:hover {
    // TODO: WTF?
    //cursor: url("/staticImages/upSign.svg"), auto !important;
  }
}
// Focus fix
:focus,
:focus-visible {
  outline: none;
  border: none;
}

// Global elements
.button {
  cursor: pointer;
  display: flex;
  img {
    margin: auto;
  }
  span,
  strong {
    margin: auto;
    text-align: center;
  }
  strong {
    text-transform: uppercase;
  }
  .cross-left {
    background-image: url("/staticImages/cross.svg");
    width: 1rem;
    background-repeat: no-repeat;
    margin-right: 0.5rem;
    height: 1rem;
    background-position: center;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
  }
  &.small {
    padding: 10px;
    border-radius: 8px;
    &.gray {
      color: $blue;
    }
  }
  &.medium {
    padding: 12px;
    min-height: 40px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
  }
  &.large {
    padding: 16px;
    min-height: 56px;
    border-radius: 8px;
  }
  &.transparentWhite {
    color: $lightGray;
    background-color: $transparentWhite;
  }
  &.round {
    border-radius: 50%;
  }
  &.gray,
  &.light-gray {
    background-color: $lightGray;
    color: $blue;
    &.arrow-left {
      &:before {
        background-image: url("/staticImages/arrowRightDarkerBlue.svg");
      }
    }
    &.cross-left {
      &:before {
        background-image: url("/staticImages/cross.svg");
      }
    }
    &.arrow-right {
      &:after {
        background-image: url("/staticImages/arrowRightDarkerBlue.svg");
      }
    }

    &.caret-left {
      &:before {
        background-image: url("/staticImages/caretRightBlue.svg");
      }
    }
    &.caret-right {
      &:after {
        background-image: url("/staticImages/caretRightBlue.svg");
      }
    }
    &.border {
      border: 1px solid #d5dcf4;
    }
  }
  &.gray {
    color: $darkBlue;
  }
  &.blue {
    background-color: $lightBlue;
    color: $white;
    &.eshop {
      background-color: $blueBackground;
    }
  }
  &.yellow {
    background-color: $yellow;
    color: $darkerBlue;
    &.arrow-right {
      &:after {
        background-image: url("/staticImages/arrowRightDarkerBlue.svg");
      }
    }
    &.caret-right {
      &:after {
        background-image: url("/staticImages/caretRightBlue.svg");
      }
    }
  }
  &.inline {
    display: inline-flex;
  }
  &:after {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:before {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.zoom-in {
    &:after {
      content: " ";
      display: block;
      height: 18px;
      width: 18px;
      background-image: url("/staticImages/icons/zoomIn.svg");
    }
  }
  &.trashCan {
    &:after {
      content: " ";
      display: block;
      height: 18px;
      width: 18px;
      background-image: url("/staticImages/icons/trashCan.svg");
    }
  }
  &.trashCan_before {
    &:before {
      content: " ";
      display: block;
      height: 18px;
      width: 18px;
      padding-right: 10px;
      background-image: url("/staticImages/icons/trashCan.svg");
    }
  }
  &.download {
    &:before {
      content: " ";
      display: block;
      height: 18px;
      width: 18px;
      padding-right: 10px;
      background-image: url("/staticImages/download.svg");
    }
  }
  &.caret-left,
  &.arrow-left {
    position: relative;
    span {
      margin-left: 30px;
      margin-right: 10px;
    }
    &:before {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: rotate(180deg) translateY(49%);
      content: " ";
      display: block;
      height: 15px;
      max-width: 15px;
      width: 100%;
    }
  }
  &.caret-right,
  &.arrow-right {
    position: relative;
    span {
      margin-left: 10px;
      margin-right: 30px;
    }
    &.full-width {
      span {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &:after {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      content: " ";
      display: block;
      height: 15px;
      max-width: 15px;
      width: 100%;
    }
  }
  &.caret-right {
    &:after {
      background-image: url("/staticImages/caretRight.svg");
    }
  }
  &.arrow-right {
    &:after {
      background-image: url("/staticImages/arrowRight.svg");
    }
  }
  &.full-width{
    width: 100%;
  }
}

hr {
  border: none;
  border-bottom: 1px solid $white;
  &.blue {
    border-color: $separatorBlue;
  }
  &.offWhite {
    border-color: $offWhite;
  }
  &.m-20 {
    margin: 20px 0;
  }
  &.transparentBlack {
    border-color: $transparentHr;
  }
  &.transparentBlue {
    border-color: $transparentBlue;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &:link {
    color: inherit;
    text-decoration: none;
  }
  &:visited {
    color: inherit;
    text-decoration: none;
  }
  &:active {
    color: inherit;
    text-decoration: none;
  }
  &.underlinedLink{
    text-decoration: underline;
  }
  &.blueLink{
    color: $blue;
  }
}

p {
  font-size: 13px;
  &.no-spacing {
    margin: 0;
    padding: 0;
  }
  &.white {
    color: $white;
  }
  &.manrope {
    font-family: $manrope;
  }
  &.font-size-12{
    font-size: 12px;
  }
}

.contentWrapper{
  padding-top: 150px;
  @media (max-width: $mobileMaxWidth) {
    padding-top: 180px;
  }
}


.uppercase {
  text-transform: uppercase;
}

$margins: 0 5 7 10 12 15 20 25 30 35 40 45 50;
@each $margin in $margins {
  .m-#{$margin} {
    margin: $margin + px !important;
  }
  .mbt-#{$margin} {
    margin-top: $margin + px !important;
    margin-bottom: $margin + px !important;
  }
  .mt-#{$margin} {
    margin-top: $margin + px !important;
  }
  .mb-#{$margin} {
    margin-bottom: $margin + px !important;
  }
  .mr-#{$margin} {
    margin-right: $margin + px !important;
  }
  .ml-#{$margin} {
    margin-left: $margin + px !important;
  }
  .mlr-#{$margin}{
    margin-left: $margin + px !important;
    margin-right: $margin + px !important;
  }
}
.m-auto {
  margin: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mbt-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
@each $margin in $margins {
  @media (max-width: $mobileMaxWidth) {
    .m-#{$margin}-mobile {
      margin: $margin + px !important;
    }
    .mbt-#{$margin}-mobile {
      margin-top: $margin + px !important;
      margin-bottom: $margin + px !important;
    }
    .mt-#{$margin}-mobile {
      margin-top: $margin + px !important;
    }
    .mb-#{$margin}-mobile {
      margin-bottom: $margin + px !important;
    }
    .mr-#{$margin}-mobile {
      margin-right: $margin + px !important;
    }
    .ml-#{$margin}-mobile {
      margin-left: $margin + px !important;
    }
    .mlr-#{$margin}-mobile{
      margin-left: $margin + px !important;
      margin-right: $margin + px !important;
    }

    .p-#{$margin}-mobile {
      padding: $margin + px !important;
    }
    .pbt-#{$margin}-mobile {
      padding-top: $margin + px !important;
      padding-bottom: $margin + px !important;
    }
    .pt-#{$margin}-mobile {
      padding-top: $margin + px !important;
    }
    .pb-#{$margin}-mobile {
      padding-bottom: $margin + px !important;
    }
    .pr-#{$margin}-mobile {
      padding-right: $margin + px !important;
    }
    .pl-#{$margin}-mobile {
      padding-left: $margin + px !important;
    }
    .plr-#{$margin}-mobile{
      padding-left: $margin + px !important;
      padding-right: $margin + px !important;
    }
  }
}

@media (max-width: $mobileMaxWidth){
  .m-auto-mobile {
    margin: auto !important;
  }
  .mt-auto-mobile {
    margin-top: auto !important;
  }
  .mbt-auto-mobile {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mlr-auto-mobile {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .ml-auto-mopbile {
    margin-left: auto !important;
  }
}

$gutter-widths: 10px 15px 20px 52px;
$column-widths: 100 90 80 75 70 66 60 50 40 33 30 25 20 10;
.row {
  display: flex;
  flex-direction: row;
  &.wrap {
    flex-wrap: wrap;
  }
  @each $gutter-width in $gutter-widths {
    &.gutter-#{$gutter-width} {
      margin: -$gutter-width / 2;
      > .col {
        margin: $gutter-width / 2;
        @each $column-width in $column-widths {
          &.w-#{$column-width}p {
            width: calc(#{$column-width + "%"} - #{$gutter-width});
          }
        }
      }
    }
  }
  .col {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    &.vertical-centering {
      margin-top: auto;
      margin-bottom: auto;
    }
    @each $column-width in $column-widths {
      &.w-#{$column-width}p {
        width: #{$column-width + "%"};
      }
    }
    &.w-auto {
      flex: 1;
    }
    hr {
      margin: 0;
    }
  }
}

.widget {
  &.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .button {
      flex: 1;
      margin: 0 12px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.container {
  max-width: $desktopContentWidth;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
  &.full-width {
    max-width: none;
    width: 100%;
  }
  .pageHeader {
    width: 100%;
    padding-bottom: 13px;
    border-bottom: solid 1px $offWhite;
    margin-bottom: 30px;
    h1 {
      margin: auto 0;
      font-size: 24px;
      font-weight: 300;
      text-transform: uppercase;
    }
    @media(max-width: $mobileMaxWidth){
      padding-bottom: 20px;
    }


  }
  .secondHeader {
    width: 100%;
    padding-bottom: 13px;
    h1 {
      margin: auto 0;
      font-size: 24px;
      font-weight: 300;
      text-transform: uppercase;
    }
  }
  .pageContent {
    font-size: 13px;
    color: $gray;
  }
  @media (max-width: $mobileMaxWidth) {
    .row .col.mbt-25 {
      margin-top: 0 !important;
    }
  }

  ul {
    padding-inline-start: 20px;
    li {
      list-style-type: ">   ";
      &::marker {
        color: $darkBlue;
      }
    }
  }
}

.box-shadow {
  border-radius: 8px;
  box-shadow: $grayShadow;
  background-color: $white;
}

.caret-down {
  &:after {
    display: block;
    content: "";
    width: 13px;
    height: 8px;
    background: url("/staticImages/icons/caret.svg");
  }
}

.box {
  padding: 35px;
}

.card {
  padding: 15px;
  border-radius: 8px;
  box-shadow: $pillShadow;
  background-image: linear-gradient(122deg, #eff0f4 4%, #ffffff);
  text-align: center;
  h4 {
    font-size: 24px;
    font-weight: 300;
    color: $darkBlue;
    margin: 0 0 10px 0;
  }
  .icon {
    max-width: 100%;
    width: auto;
    height: 36px;
    display: inline-block;
  }
}

.bottom-right-arrow {
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    bottom: -27px;
    right: -27px;
    display: block;
    content: " ";
    color: white;
    width: 0;
    height: 0;
    border: 27px solid transparent;
    border-left-color: $lightBlue;
    transform: rotate(45deg);
  }
  &:after {
    content: " ";
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: block;
    width: 13px;
    height: 13px;
    background-image: url("/staticImages/cornerArrow.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $darkBlue;
  &.full-width {
    width: 100%;
  }
}
h1 {
}
h2 {
}
h3 {
  margin: 0 0 25px 0;
  font-size: 24px;
  font-weight: 300;
  color: $darkBlue;
  text-transform: uppercase;
}
h4 {
  font-size: 13px;
  line-height: 1.15;
  color: $gray;
  margin: 0 0 25px 0;
}
h3 + h4 {
  margin-top: -20px;
}
h5 {
}
h6 {
}

section > .header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  h3 {
    margin-top: auto;
    margin-bottom: auto;
  }
  h4 {
    margin-top: auto;
    margin-bottom: auto;
  }
  h3 + h4 {
    margin-top: 5px;
  }
  @media (max-width: $mobileMaxWidth) {
    margin-bottom: 20px;
  }
}

// Displays:
$displays: flex block inline;
@each $display in $displays {
  .d-#{$display} {
    display: $display;
  }
}

// Heights:
$heights: 100;
@each $height in $heights {
  &.h-#{$height}p {
    height: #{$height + "%"};
  }
}

//panel
.panel {
  background-color: #f2f3f5;
  border-radius: 8px;
  &.fullWidthInput{
    input{
      width: 100%;
    }
  }
  .panelHeader {
    padding: 9px 25px 8px 35px;
    border-bottom: solid 1px rgba(97, 126, 243, 0.2);
    h2 {
      font-size: 20px;
      font-weight: 300;
      color: $darkBlue;
    }
    &.borderTop {
      border-top: solid 1px rgba(97, 126, 243, 0.2);
    }
  }
  .panelBody {
    padding: 24px 25px 24px 35px;
    &.buttons {
      padding: 24px 25px 35px 35px;
    }
    &.panel-grid-2 {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-auto-flow: column;
      input {
        width: 90%;
      }
      .grid-left {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      .grid-right {
        grid-column-start: 2;
        grid-column-end: 3;
      }
    }
    &.panel-grid-3 {
      display: grid;
      grid-template-columns: repeat(3, auto);
    }
    &.panel-grid-4 {
      display: grid;
      grid-template-columns: repeat(4, auto);
    }
    &.flex-row {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    @media(max-width: $mobileMaxWidth){
      &.panel-grid-1-mobile{
        grid-template-columns: auto;
        grid-auto-flow: row;
        .grid-right{
          grid-column-end: auto;
          grid-column-start: auto;
        }
      }
      &.flex-mobile-col{
        flex-direction: column;
      }
    }
  }

  label {
    width: 100%;
    display: grid;
    align-items: center;

    &.grid-80-130 {
      grid-template-columns: 80px 130px auto;
    }

    &.grid-80 {
      grid-template-columns: 80px auto;
    }
    &.grid-105 {
      grid-template-columns: 105px auto;
    }
    &.grid-135 {
      grid-template-columns: 135px auto;
    }
    &.grid-auto {
      grid-template-columns: auto auto;
    }

    &.flex {
      display: flex;
      width: auto;

      span {
        min-width: auto;
        white-space: nowrap;
      }
    }

    span {
      color: $darkBlue;
      font-size: 15px;
      font-weight: 500;
      min-width: 80px;

      &.checkbox {
        position: relative;
        top: 0;
        left: 0;
        height: 19px;
        width: 19px;
        background-color: $white;
        border: 1px solid $lightestBlue;
        min-width: auto;
        border-radius: 4px;

        &.checked {
          &:after {
            content: " ";
            background-position: center center;
            background-repeat: no-repeat;
            // background-size: contain;
            display: block;
            height: 17px;
            width: 17px;
            background-image: url("/staticImages/icons/check-box.svg");
          }
        }
      }
      &.radio {
        position: relative;
        margin: 10px;
        height: 18px;
        width: 18px;
        background-color: $white;
        border: 1px solid $lightestBlue;
        border-radius: 50%;
        &.checked {
          background-color: $lightGray;
          &:after {
            content: " ";
            display: block;
            height: 9px;
            width: 9px;
            background-color: $darkBlue;
            border-radius: 50%;
            top: 3.5px;
            left: 3.5px;
            position: relative;
          }
        }
      }
    }
    input[disabled]{
      ~ span, ~ span span{
        color: $gray;
      }
    }
    &.error{
      span{
        &.checkbox, &.radio{
          border: 2px solid $redNotification;
          width: 20px;
          height: 20px;
        }
      }
      input{
        border: 2px solid $redNotification;
      }
    }
  }
  select{
    padding: 13px 26px 12px 26px;
    width: 85%;
    border: none;
    border-radius: 8px;
  }
  input {
    padding: 13px 26px 12px 26px;
    width: 85%;
    border: none;
    border-radius: 8px;

    &[type="checkbox"] {
      width: auto;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &[type="radio"] {
      width: auto;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      //margin: 10px;
      //width: auto;
    }
  }
  textarea {
    border-radius: 8px;
    border: none;
    min-height: 100px;
    width: 96%;
    padding: 15px;
  }
  select, input, textarea{
    &.error{
      border: 2px solid $darkRed;
    }
  }
  .button {
    &.medium {
      padding: 12px 20px;
      min-width: 140px;
    }
  }
}
.input_number {
  padding: 8px 10px;
  min-width: 60%;
  border-radius: 8px;
  border: 1px solid $offWhite;
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $blue;

  div {
    input {
      border: none;
      width: 30px;
      display: inline-block;
      color: inherit;
      font-weight: 700;
      &:focus {
        outline: none;
      }
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  .spin_buttons {
    display: flex;
    flex-direction: column;
    width: 14px;
    .spin_top {
      height: 10px;
      width: 14px;
      position: relative;
      padding: 2px;
      cursor: pointer;
      &:after {
        content: " ";
        position: absolute;
        display: block;
        width: 10px;
        height: 6px;
        background-image: url("/staticImages/spin_top.svg");
        //background-color: red;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .spin_bottom {
      height: 10px;
      width: 14px;
      padding: 2px;
      position: relative;
      cursor: pointer;
      &:after {
        content: " ";
        position: absolute;
        //bottom: 5px;
        //right: 5px;
        display: block;
        width: 10px;
        height: 6px;
        background-image: url("/staticImages/spin_bottom.svg");
        //background-color: purple;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
.input_dropdown {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .dropdown_choosen {
    cursor: pointer;
    background-color: $white;
    border: 1px solid $offWhite;
    border-radius: 8px;
    padding: 12px 15px;
    span {
      font-size: 14px;
      font-weight: 500;
      color: $blue;
    }
    &:after {
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      content: "";
      top: 14px;
      right: 10px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      background-image: url("/staticImages/dropdown.svg");
    }
  }
  .variants {
    display: none;
    position: absolute;
    top: 42px;
    width: 100%;
    background-color: $white;
    border: 1px solid $offWhite;
    border-radius: 8px;
    color: $blue;
    &.open {
      display: block;
    }

    .variant {
      padding: 12px 15px;
      &:hover {
        background-color: $lightBlue;
        color: $white;
      }
    }
  }
}

//responsive table
.table-responsive {
  .table-header,
  .table-content-item,
  .table-footer {
    display: grid;
    & > div {
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
    }
  }
  .table-header {
    background-color: $lightGray;
    color: $darkBlue;
    font-size: 14px;
    font-weight: 500;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 1px solid $offWhite;
    & > div {
      border-right: 1px solid $offWhite;
      &:first-child {
        border-left: 1px solid $offWhite;
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
      }
    }
  }
  .table-content-item {
    color: $gray;
    font-size: 14px;
    font-weight: 500;
    border-top: 1px solid $offWhite;
    &.last-item {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-bottom: 1px solid $offWhite;
      & > div {
        &:first-child {
          //border-left: 1px solid $offWhite;
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
    & > div {
      border-right: 1px solid $offWhite;
      &:first-child {
        border-left: 1px solid $offWhite;
      }
    }
  }
  .table-footer {
    .table-footer-item-1-2 {
      grid-column: 1 / 2;
    }
    .table-footer-item-4-5 {
      grid-column: 4 / 5;
    }
    .table-footer-item-5-6 {
      grid-column: 5 / 6;
    }
  }
}

.text-center {
  text-align: center;
}
.hidden {
  display: none;
}

.hidden-desktop{
  @media(min-width: ($mobileMaxWidth + 1)){
    display: none !important;
  }
}
.hidden-mobile{
  @media(max-width: $mobileMaxWidth){
    display: none !important;
  }
}

.cursor-auto{
  cursor: auto !important;
}

.text-red{
  color: $redNotification;
}

.popupBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);

  .popupContainer{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 80vw;
    background: $white;
    border-radius: 8px;
    box-shadow: $boxShadow;
    min-width: 300px;
    border: 5px solid red;
  }

  h2{
    padding: 0 20px;
    text-align: center;
    font-size: 32px;
  }

  .popupContents{
    margin-bottom: 10px;
    padding: 0 20px;
  }

  .popupButtons{
    display: flex;
    margin-left: auto;
    .popupButton{
      margin: auto 10px;
    }
  }
}
