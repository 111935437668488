@import "../style/globals";

.pills {  
  justify-content: center;
  .pill {
    background-image: $grayWhiteGradientBackground;
    padding: 17px 10px 10px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: $darkBlue;
    cursor: pointer;
    width: -webkit-fill-available;
    span {
      text-align: left;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 1rem;
    }
  }
}

@media (max-width: $mobileMaxWidth){
  .row.pills{
    // flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 5px 10px;
    margin: 0 !important;
    .col.pill{
      width: calc(50% - 10px);
      margin: 5px;
      min-width: 180px;
    }

    &.homepage {
      .col.pill {
        width: 100%;
        max-width: 280px;
      }
    }
  }
}
