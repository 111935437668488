@import "../style/globals";
.productListing{
  .productsRowWrapper{
    position: relative;
    .arrow-right, .arrow-left{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      height: 36px;
    }
    .arrow-left{
      left: -56px
    }
    .arrow-right{
      right: -56px;
    }
    .products{
      height: 410px;
      flex-wrap: nowrap;
      overflow: hidden;
      .product{
        flex-shrink: 0;
        transition: margin-left .5s ease-in-out;
      }
    }
  }
  .product{
    box-shadow: $boxShadow;
    border-radius: 12px;
    background: $white;
    .image{
      padding: 15px;
      display: flex;
      height: 175px;
      width: 100%;
      transition: all .5s;
      &:hover{
        padding: 10px;
      }
      img{
        display: block;
        margin: auto;
        max-width: 100%;
        width: auto;
        height: 100%;
      }
    }
    .productDescription{
      height: 6rem;
    }
    .name, .id{
      padding: 0 15px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.07;
      text-align: center;
      color: $darkBlue;
      display: block;
    }
    .excerpt{
      margin: 10px 20px;
      font-size: 13px;
      line-height: 1.15;
      text-align: center;
      color: $gray;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 28px;
    }
    .price{
      margin: 0 15px;
      display: flex;
      font-family: $manrope;
      font-weight: 800;
      text-align: center;
      .amount-pre-sale{
        font-size: 15px;
        text-decoration: line-through;
        color: $darkRed;
        margin: auto 15px auto auto;
        &+.amount{
          margin-left: 0;
        }
      }
      .amount{
        margin: auto;
        font-size: 17px;
        color: $darkBlue;
      }
    }
    .stockInfo{
      margin: 0 10px 10px;
      color: $darkerGreen;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      display: block;
    }
    .amountBuy{
      .buyAmount{
        min-width: auto;
        margin: 10px 0 10px 10px;
        flex: 1;
        > span{
          margin: auto 5px auto 0;
        }
        .spin_buttons{
          margin: auto 0;
          .spin_top{
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}

@media (max-width: $mobileMaxWidth){
  .productListing{
    padding: 20px 15px;
    background: $lightGray;
    .header{
      margin-top: 5px;
      margin-bottom: 25px;
      h3{
        margin: auto;
      }
      .button{
        display: none;
      }
    }
    .productsRowWrapper{
      .row.products{
        flex-wrap: wrap;
        height: auto;
        .product{
          width: calc(50% - 10px);
          margin: 5px !important;
          &:nth-child(n + 5){
            display: none;
          }

          .productDescription{
            height: auto;
            .excerpt{
              display: none;
            }
            .name {
              min-height: 4.28em;
            }
          }
          .price {
            flex-direction: column;
            .amount-pre-sale {
              margin: auto;
            }
            .amount {
              margin: auto;
            }
          }
          .amountBuy{
            .buyAmount{
              margin-right: 10px;
            }
          }

          .button {
            margin-top: auto !important;
          }
        }
      }
    }
  }
}
