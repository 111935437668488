@import "../style/globals";

.verify {
    .pageForm {
        .panel {
            .buttonsWrapper {
                .button.small.gray {
                    border: solid 1px $gray;
                }
            }
        }

    }
}

@media(max-width: $mobileMaxWidth){
    .verify {
        .pageForm {
            flex-direction: column;
            .col.w-66p {
                width: 100%;
            }
            .col.w-33p {
                width: 100%;
            }
        }
        .panel {
            label.grid-105 {
                grid-template-columns: 85px auto;
            }
            .buttonsWrapper {
                flex-direction: column;
                gap: 10px;
                .button {
                    width: 100%;
                }
            }
        }
        
    }
}