@import "../style/globals";

@media(max-width: $mobileMaxWidth){
    .account {
        .row.gutter-20px {
            flex-direction: column;
            .col.w-66p {
                width: calc(100% - 20px);
            }
            .col.w-33p {
                width: calc(100% -20px);
            }
        }
        .panel {
            input {
                width: 100%;
            }
        }
        
    }
    
}
