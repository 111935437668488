@import "../style/globals";

.logIn {
    .panel {
        .buttonsWrapper {
            gap: 10px;
        }
    }
}

@media(max-width: $mobileMaxWidth){
    .logIn {
        .row.gutter-20px {
            flex-direction: column;
        }
        .pageContent {
            flex-direction: column;
            .col.w-66p {
                width: 100%
            }
            .col.w-33p {
                width: 100%
            }
        }
        .panel {
            input {
                width: 100%;
            }
            .panelBody.buttons {
                padding: 24px 15px 35px 15px;
            }
            .buttonsWrapper {
                flex-direction: column;
                gap: 10px;
                .button {
                    width: 100%;
                    
                }
            }
        }
    }
}
