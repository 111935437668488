@import "../style/globals";
.eshopSidebar{

  &.hidden-desktop {
    a {
      line-height: 2.4em;
    }
  }

  .hide {
    display: none;
  }

  .active ul:only-child {
    display: initial;
  }

  .first {
    //background-color: white;
    padding: 1rem 0 1rem 0;
    padding-inline-start: 0.75rem;
  }

  ul,
  li {
    list-style-type: none;
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
    color: $blueBackground;
    a {
      color: #5d5edf;
    }
  }

  li{
    display: flex;
    flex-direction: column;
    padding-inline-start: 0.40rem;
    margin-left: 0.35rem;
  }

  ul.active{
    //background: rgba(0,0,0,0.05);
    padding-bottom: 0.75rem !important;
    > li.active{
      color: red;
    }
  }
  a {
    padding: 0.3rem 0.5rem;
    color: $blue;
    &:hover {
      color: $darkBlue;
    }
  }
  ul {
    list-style: none;
  }

  .sideBar {
    background-color: $lightGray;
    border-radius: 8px;
    margin: 0;
    padding: 0.25rem 1rem 0.25rem 0rem;

    ul.active {
      padding: 0;
    }

    > li {
      padding-bottom: 13px;
      padding-top: 13px;
      border-bottom: 1px solid #8989893b;
      &.inactive, &.active{
        border-left: none !important;
      }
    }
    li{
      position: relative;
      &.inactive{
        border-left: 2px solid;

        &:hover {
          background-color: $lightBlueHover;
          border-radius: 4px;
        }
      }
      &.active{
        ~ li.inactive{
          border-left: none;
          > a {
            display: flex;

            .caret-right {
              background-image: url("/staticImages/pathCaretRightBlue.svg");
            }
            .linkName {
              display: flex;
              text-align: start;
              padding: 0;
            }
          }
        }
        &.first{
          &:before{
            display: none;
          }
          &:after{
            display: none;
          }
          .active{
            ul{
              > li:last-child{
                border-bottom: 1px solid #8989893b;
                padding-bottom: 0.3rem;
                ul{
                  > li:last-child{
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
        &:before{
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 2px;
          background-color: $blueBackground;
        }
        &:after{
          content: ' ';
          display: block;
          position: absolute;
          top: 16px;
          left: -4px;
          height: 6px;
          width: 10px;
          background-image: url("/staticImages/icons/caretBlue.svg");
          background-size: contain;
          background-repeat: no-repeat;
        }
        > a {
          display: flex;
          background-color: $blueBackground;

          color: white;
          border-radius: 4px;
          padding: 7px 12px;
          justify-content: space-between;
          .caret-right {
            background-image: url("/staticImages/pathCaretRightWhite.svg");
            
          }
          .linkName {
            display: flex;
            text-align: start;
            padding: 0;
          }
        }
        &.current{
          > a{
            background: $darkBlue;
            
          }
          li{
            &.inactive{
              border-left: none;
            }
          }
        }
      }

     
    }

    > li:last-child {
      border: none;
    }
    span {
      padding-left: 1rem;
      background-repeat: no-repeat;
      background-position: left;
    }
  }
}
