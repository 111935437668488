@import "../style/globals";

.categoryDescription{
  h1{
    margin-top: 0;
  }

}

@media (max-width: $mobileMaxWidth){
  .categoryDescription{
    h1 {
      margin: auto 0;
      font-size: 32px;
      font-weight: 300;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
  }
}
