// Bootstrap changes here! They must be before bootstrap import to take effect e.g.:
//$theme-colors: (
//    "info": tomato,
//    "danger": teal
//);

html.admin{
  @import "~bootstrap/scss/bootstrap";
  @import "~bootstrap-icons/font/bootstrap-icons.css";
  header{
    top: 0;
    z-index: 10;
  }
  .contentWrapper{
    padding-top: 0;
  }
  .text-transform-none{
    text-transform: none !important;
  }
  .sidebar{
    top: calc(1.63rem + 50px);
    min-height: calc(100vh - 1.63rem - 50px);
    z-index: 9;
    @include media-breakpoint-down(md) {
      min-height: auto;
    }
    .linkParent{
      background: rgba(255, 255, 255, 0.1);
      padding: 5px;
      border-radius: 5px;
      .parentName{
        cursor: pointer;
        display: flex;
        span{
          margin-right: auto;
        }
      }
      .childrenContainer{
        display: none;
      }
      &.open{
        .parentName{
          i{
            transform: rotate(180deg);
          }
        }
        .childrenContainer{
          display: block;
        }
      }
    }
  }
  .se-component-product-listing{
    position: relative;
    min-height: 100px;
    //&:after{
    //  display: block;
    //  content: "Seznam produktů se vykresluje jen na frontendu";
    //}
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .editor{
    .innerWrapper{
      border: 1px solid $gray-600;
      margin: 1rem 0;
      padding: .5rem;
      border-radius: .25rem;
      .DraftEditor-root{
        width: 100%;
        height: 600px;
        overflow-y: auto;
        box-sizing: border-box;
      }
      .countWrapper{
        margin: .5rem -0.5rem 0;
        padding: .5rem .5rem 0;
        border-top: 1px solid $gray-600;
      }
    }

  }
  .link{
    text-decoration: underline;
    color: $primary;
  }
  .editorRow{
    border: 1px solid $gray-600;
    padding: 10px;
    margin: 0;
    .editorCol{
      border: 1px solid $gray-600;
      padding: 10px;
    }
  }
  .hover-child-bg{
    >:hover{
      background: rgba($gray-500, 0.5);
    }
  }
  @for $i from 1 through 5{
    .border-#{$i}{
      border-width: #{$i}px !important;
    }
  }

  .date-picker{
    z-index: 9;
    .react-datepicker-popper{
      z-index: 9;
    }
  }

}
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/root";
