@import '../style/globals';

.calendar_input_wrapper {
    position: relative;

    .selected_month {
        display: flex;
        align-items: center;
        font-size: 16px;
    }
    .selected_year {
        display: flex;
        align-items: center;
        font-size: 16px;
    }
    .caret_left {
        width: 1em;
        height: 1em;
        display: inline-block;
        background-image: url("/staticImages/caretLeftBlue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .caret_right {
        width: 1em;
        height: 1em;
        display: inline-block;
        background-image: url("/staticImages/caretRightBlue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .calendar_input {
        width: 100%;
        // height: 36px;
        border-radius: 8px;
        background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(100deg, #f2f3f5 5%, #fff);
        position: relative;
        &::after {
            content: "";
            display: block;
            width: 14px;
            height: 16px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translate(0, -50%);
            background-image: url("/staticImages/calendar.svg");
        }
        .placeholder {
            color: $darkBlue;
            font-size: 13px;
            &.required {
                &::after {
                    content: '*';
                }
            }
        }
    }

    .date_picker_wrapper {
        display: none;
        &.open_calendar {
            display: block;
        }
        
        
        position: absolute;
        top: 1.5em;
        right: 0;
        background-color: $white;
        border: 1px solid $darkBlue;
        padding: 15px;
        color: $darkBlue;
        font-weight: bold;
        border-radius: 1em 0 1em 1em;

        .date_picker {
            display: flex;
            // gap: 20px;
            font-size: 16px;
            
            // width: 100%;
            .current_info {
                padding-right: 10px;
                border-right: 1px solid $darkBlue;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .rent_from_date {
                    &>div {
                        height: 20px;
                    }
                }
                .rent_to_date {
                    &>div {
                        height: 20px;
                    }
                }
            }
            .calendar {
                padding-left: 10px;
                .header {
                    padding: 0.5em;
                    display: flex;
                    justify-content: space-between;
                }
                
            }
        }
        
        .days_names {
            display: grid;
            grid-template-columns: repeat(7, auto);
            text-align: center;
            padding-bottom: 0.5em;
            column-gap: 0.5em;
            color: $darkBlue;

            span {
                width: 1.5em;
                // background-color: red;
            }
        }
        .days {
            display: grid;
            grid-template-columns: repeat(7, auto);
            text-align: center;
            row-gap: 0.5em;
            column-gap: 0.5em;
            span {
                width: 1.5em;
                // background-color: rebeccapurple;
                &:hover {
                    background-color: $darkBlue;
                    color: $lightGray;
                }
                &.active {
                    background-color: $darkBlue;
                    color: $lightGray;
                }
            }
            .today {
                text-decoration: underline;
            }
        }

    }
}