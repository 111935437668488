@import "../style/globals";

.table-responsive {
  &.orders {
    .table-header, .table-content-item{
      grid-template-areas: "id date paymentmethod orderstate addtocart pricewithtax detailfa";
      grid-template-columns: 15% 15% 15% 13% 15% 13.5% 13.5%;
      & > div {
        padding: 17px;
        justify-content: center;
      }
    }
    .id {
      grid-area: id;
    }
    .date {
      grid-area: date;
    }
    .paymentmethod {
      grid-area: paymentmethod;
    }
    .orderstate {
      grid-area: orderstate;
    }
    .addtocart {
      grid-area: addtocart;
      .button {
        width: 100%;
        justify-content: center;
        img {
          margin: 0 10px 0 0;
        }
        span {
          margin: 0;
        }


      }
    }
    .pricewithtax {
      grid-area: pricewithtax;
    }
    .detailfa {
      grid-area: detailfa;
      .col {
        border-right: 1px solid $offWhite;
        flex-direction: row;
        margin-right: 5px;
        padding-right: 5px;
        &:last-child {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }
      }

    }
  }
}

@media(max-width: $mobileMaxWidth){
  .table-responsive {
    &.orders {
      padding: 0 15px;
      .table-header {
        display: none;
      }
      .table-content-item{
        grid-template-areas:
        'date pricewithtax'
        'id detailfa';
        grid-template-columns: 50% 50%;

        & > div {
          border: none;
          flex-direction: column;
          align-items: inherit;
          &:first-child {
            border: none;
          }
          &.id {
            // justify-content: left;
            text-align: left;
            border-left: 1px solid $offWhite;
            padding: 4px 4px 12px 12px;
          }
          &.date {
            // justify-content: left;
            text-align: left;
            border-left: 1px solid $offWhite;
            padding: 12px 4px 4px 12px;
          }
          &.pricewithtax {
            // justify-content: right;
            text-align: right;
            border-right: 1px solid $offWhite;
            padding: 12px 12px 4px 4px;
          }
          &.detailfa {
            // justify-content: right;
            text-align: right;
            border-right: 1px solid $offWhite;
            padding: 4px 12px 12px 4px;
            .row {
              justify-content: end;
            }
          }
        }

      }
   

      .addtocart {
        display: none;
      }
      .paymentmethod {
        display: none;
      }
      .orderstate {
        display: none;
      }

    }
  }
}
